import logo from './media/logo.png';
import playground from './media/playground_logo.png';
import cover from './media/playground_cover_1000.jpg';
import hero from './media/hero1000.png';
import etiennemachine1 from './media/etienne1_1000.jpg';

import gallery1 from './media/gallery/julien2.jpeg';
import gallery2 from './media/gallery/sacha2.jpg';
import gallery3 from './media/gallery/arno2.jpg';
import gallery5 from './media/gallery/asher2.jpg';
import gallery6 from './media/gallery/etienne6.jpg';
import gallery7 from './media/gallery/etienne9.jpg';
import gallery8 from './media/gallery/etienne_analo.jpg';
import gallery9 from './media/gallery/thibault1.jpg';

import etienneMachineCover from './media/releases/etienneMachine.jpg';
import offAndOffCover from './media/releases/offAndOff.jpg';
import onAndOnCover from './media/releases/onAndOn.jpg';
import overAndOutCover from './media/releases/overAndOut.jpg';

import alexis from './easterMachine20.mp3';

import instagram from './media/socials/instagram.svg';
import facebook from './media/socials/facebook.svg';
import bandcamp from './media/socials/bandcamp.svg';
import spotify from './media/socials/spotify.svg';
import youtube from './media/socials/square-youtube.svg';

import './App.css';

import useSound from 'use-sound';

import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { data } from './data.js';

function App() {

  const [searchParams] = useSearchParams({ pos: 0, page: 'home', lang: 'fr' });
  const position = searchParams.get("pos");
  const page = searchParams.get("page");
  const lang = searchParams.get("lang");

  const createToLink = (positionValue, pageValue, langValue) => {
    return `?pos=${positionValue}&page=${pageValue}&lang=${langValue}`;
  };

  // const [lang, setLang] = useState('fr');

  // const [position, setPosition] = useState(0);
  // const [page, setPage] = useState('home');

  // const [touchStart, setTouchStart] = useState(null);
  // const [touchEnd, setTouchEnd] = useState(null);

  const [easterCount, setEasterCont] = useState(0);
  const [playSound] = useSound(alexis);

  const countUp = () => {
    if (easterCount > 8) {
      playSound();
      setEasterCont(0);
    } else {
      setEasterCont(easterCount + 1);
    }
  }

  // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 90;

  // const onTouchStart = (e) => {
  //   setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientX)
  // }

  // const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return
  //   const distance = touchStart - touchEnd
  //   const isLeftSwipe = distance > minSwipeDistance
  //   const isRightSwipe = distance < -minSwipeDistance
  //   if (isRightSwipe && position > 0) {
  //     position = position - 1;
  //   } else if (isLeftSwipe && position === 0) {
  //     position = 1;
  //   }
  //   // add your conditional logic here
  // }

  // function stateHandler(pos, page) {
  //   setPosition(pos);
  //   setPage(page);
  // }

  return (
    <div className={position === '1' ? 'position1 App' : position === '2' ? 'position2 App' : 'position0 App'}
    // onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
    >
      <div className="row" style={{ height: '100%' }}>
        <div>
          <div className="container-fluid">
            <div>
              <div id='hero' style={{ backgroundImage: `url(${hero})` }}>
                <div id='heroTextContainer'>
                  {/* <img src={hero} alt="hero" style={{ transform: 'scale(0.125)' }} /> */}
                  <img id='logo' onClick={() => countUp()} src={logo} alt='logo'></img>
                  <br />
                  <Link to={createToLink('1', page, lang)}>
                    <span id='menuButton' class="material-symbols-outlined">
                      menu
                    </span>
                  </Link>
                  <br />

                  <Link to={createToLink(position, page, lang === 'fr' ? 'en' : 'fr')}>
                    <span id='languageSwitch' class="material-symbols-outlined">
                      language
                    </span>
                  </Link>
                  <p id='language'>
                    {lang === 'fr' ? 'FR' : 'EN'}
                  </p>
                </div>
              </div>
              <ul id='nav'>
                <Link className='navItem black' to={createToLink('2', 'playground', lang)} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem black-text full-height" style={{ background: position !== '2' ? null : page === 'playground' ? '#24110c' : null }}>
                    <img id='playgroundTab' src={playground} alt="playgrond logo" />
                  </li>
                </Link>

                <Link className='navItem black' to={createToLink('2', 'etiennemachine', lang)} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem black-text full-height" style={{
                    background: position !== '2' ? null : page === 'etiennemachine' ? '#24110c' : null,
                    color: position !== '2' ? null : page === 'etiennemachine' ? '#f0edc2' : null
                  }}>
                    <img id='etienneMachineTab' src={logo} alt="logo" />
                  </li>
                </Link>

                <Link className='navItem black' to={createToLink('2', 'concerts', lang)} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem black-text full-height" style={{
                    background: position !== '2' ? null : page === 'concerts' ? '#24110c' : null,
                    color: position !== '2' ? null : page === 'concerts' ? '#f0edc2' : null
                  }}>{'CONCERTS'}</li>
                </Link>

                <Link className='navItem black' to={createToLink('2', 'gallery', lang)} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem black-text full-height" style={{
                    background: position !== '2' ? null : page === 'gallery' ? '#24110c' : null,
                    color: position !== '2' ? null : page === 'gallery' ? '#f0edc2' : null
                  }}>{data[lang].tabs[1]}</li>
                </Link>

                <Link className='navItem black' to={createToLink('0', 'home', lang)} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem hideOnDesktop">
                    {data[lang].tabs[2]}
                  </li>
                </Link>

              </ul>
              <div id='contentContainer'>
                <Link to={createToLink('1', page, lang)}>
                  <span class="material-symbols-outlined" style={{ position: 'fixed', backgroundColor: '#f0edc280' }}>
                    arrow_back
                  </span>
                </Link>
                <div style={{ marginTop: '8rem' }}>
                  {page === 'playground' ? <Playground lang={lang} /> : page === 'etiennemachine' ? <EtienneMachine lang={lang} /> : page === 'concerts' ? <Socials lang={lang} /> : page === 'gallery' ? <Gallery /> : null}
                  <p id='poweredBy'>proudly designed by <a href="https://www.jpkvoid.com/" target='_blank' rel='noreferrer'>jpkvoid</a></p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Playground = ({ lang }) => {
  return (
    <div>
      <img id='cover' className='contentImg' src={cover} alt="cover" style={{ margin: '1.5rem auto' }} />
      <a href="https://hummus-records.com/product-tag/etienne-machine/" target='_blank' rel='noreferrer'>
        <button>{data[lang].preOrder}</button>
      </a>
      <a href="https://bfan.link/a-playground-for-the-crowd" target='_blank' rel='noreferrer'>
        <button>{data[lang].streamNow}</button>
      </a>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <div className="accordion-header">
            <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              Tracklist
            </button>
          </div>
          <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body tracklist">
              <ol>
                <p>
                  <li>I’ve Burned the Secrets</li>
                  <li>Ferocious People</li>
                  <li>Sirens</li>
                  <li>What If I Say?</li>
                  <li>Time and Time Again</li>
                  <li>The Recipe</li>
                  <li>Intel and Data</li>
                  <li>Transmissions</li>
                  <li>Not a Part Of</li>
                  <li>No Way Around</li>
                </p>
              </ol>
            </div>
          </div>
        </div>
      </div>


      <div className="accordion-item">
        <div className="accordion-header">
          <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            Credits
          </button>
        </div>
        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            <p>All compositions by Etienne Machine</p>
            <p>Etienne Machine are Thibault Besuchet, Arno Cuendet, Sacha Dumais, Léa Martinez, Julien Palluel Kochnitzky, Asher Varadi</p>
            <p>Recorded and mixed by Alexis Sudan at A.K.A Studio, Lausanne (CH) 2023<br />
              Mastered by Brian Lucey at Magic Garden Mastering, Los Angeles (US) 2023<br />
              Graphic Design by Stéphan Nappez and Etc Design (CH)</p>
          </div>
        </div>
      </div>

      {data[lang].playgroundText.map((el, i) => {
        return (
          <p key={i}>
            {el}
          </p>
        )
      })}
    </div>
  );
}

const EtienneMachine = ({ lang }) => {
  return (
    <div>
      <img id='bandPic' className='contentImg' src={etiennemachine1} alt="band" />

      <ul className='streamingList'>
        <li className='streamingItem'>
          <a href="https://etiennemachine.bandcamp.com" target='_blank' rel='noreferrer'>
            <img src={bandcamp} alt="bandcamp" />
          </a>
        </li>
        <li className='streamingItem'>
          <a href="https://open.spotify.com/artist/7AaqiuiFv3uVtkwmUA0Bwy?si=I1hlmbxgS6qLyvyGZgzYTQ" target='_blank' rel='noreferrer'>
            <img src={spotify} alt="spotify" />
          </a>
        </li>
        <li className='socialsItem'>
          <a href="https://www.instagram.com/etienne_machine/" target='_blank' rel='noreferrer'>
            <img src={instagram} alt="instagram" />
          </a>
        </li>
        <li className='socialsItem'>
          <a href="https://www.facebook.com/etiennemachine" target='_blank' rel='noreferrer'>
            <img src={facebook} alt="facebook" />
          </a>
        </li>
        <li className='socialsItem'>
          <a href="https://www.youtube.com/@etiennemachine8159" target='_blank' rel='noreferrer'>
            <img src={youtube} alt="youtube" />
          </a>
        </li>
      </ul>

      <div className="accordion" id="accordionFlushExample">
        <div className="accordion-item">
          <div className="accordion-header">
            <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              Line-Up
            </button>
          </div>
          <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <h2>Thibault Besuchet</h2>
              <p>{data[lang].lineUp.thibault}</p>
              <h2>Arno Cuendet</h2>
              <p>{data[lang].lineUp.arno}</p>
              <h2>Sacha Dumais</h2>
              <p>{data[lang].lineUp.sacha}</p>
              {/* <h2>Léa Martinez</h2>
              <p>{data[lang].lineUp.lea}</p> */}
              <h2>Julien Palluel Kochnitzky</h2>
              <p>{data[lang].lineUp.julien}</p>
              <h2>Asher Varadi</h2>
              <p>{data[lang].lineUp.asher}</p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <div className="accordion-header">
            <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              {lang === 'en' ? 'Discography' : 'Discographie'}
            </button>
          </div>
          <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">

              <h2>Over & Out</h2>
              <img className='contentImg' src={overAndOutCover} alt="over and out cover" />
              <ul className='streamingList' style={{ justifyContent: 'start' }}>
                <li className='streamingItem'>
                  <a href="https://etiennemachine.bandcamp.com/album/over-out" target='_blank' rel='noreferrer'>
                    <img src={bandcamp} alt="bandcamp" />
                  </a>
                </li>
                <li className='streamingItem'>
                  <a href="https://open.spotify.com/album/2tjduBKW6Oy8CfUaAYG9io?si=Z7NUeKVhS8m9c6lc_vPL0g" target='_blank' rel='noreferrer'>
                    <img src={spotify} alt="spotify" />
                  </a>
                </li>
              </ul>
              <p>released April 22, 2022</p>

              <div className="accordion accordion-flush" id="accordionFlushOverOut">
                <div className="accordion-item">
                  <div className="accordion-header">
                    <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Tracklist
                    </button>
                  </div>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOverOut">
                    <div className="accordion-body tracklist">
                      <ol>
                        <p>
                          <li>1088</li>
                          <li>Descend</li>
                          <li>RIFT (Erased Your Face) (Pavel Alternate Remix)</li>
                          <li>Anthracite</li>
                        </p>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>


              <div className="accordion-item">
                <div className="accordion-header">
                  <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    Credits
                  </button>
                </div>
                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOverOut">
                  <div className="accordion-body">
                    <p>
                      “1088”, “Descend” & “Anthracite” written, performed and produced by Etienne Machine<br />
                      Recorded and mixed by Alexis Sudan, assisted by Stéphane Rezzadore at A.K.A Studio, Lausanne CH<br />
                      Mastered by John Davis at Metropolis Studio, London UK<br />
                      “RIFT (Erased Your Face) (Pavel Alternate Remix)” produced and mixed by Etienne Bel aka Pavel<br />
                      Mastered by Alexis Sudan at A.K.A Studio, Lausanne CH</p>
                    <p>
                      Etienne Machine are:<br />
                      Arno Cuendet, Thibault Besuchet, Julien Palluel Kochnitzky, Maic Anthoine, Mathieu Nuzzo, Justine Tornay, Asher Varadi<br />
                      Cover design by Etc Design and Stéphan Nappez
                      <br /><br />
                    </p>
                  </div>
                </div>
              </div>

              <h2 style={{ marginTop: '1.5rem' }}>On & On (Off & Off Remixes)</h2>
              <img className='contentImg' src={onAndOnCover} alt="on and on cover" />
              <ul className='streamingList' style={{ justifyContent: 'start' }}>
                <li className='streamingItem'>
                  <a href="https://etiennemachine.bandcamp.com/album/on-on-off-off-remixes" target='_blank' rel='noreferrer'>
                    <img src={bandcamp} alt="bandcamp" />
                  </a>
                </li>
                <li className='streamingItem'>
                  <a href="https://open.spotify.com/album/5tiD8FGRRUROaksnA8BxeG?si=9hXlNhmVQ9mmGMP05YQrug" target='_blank' rel='noreferrer'>
                    <img src={spotify} alt="spotify" />
                  </a>
                </li>
              </ul>
              <p>released September 10, 2021</p>

              <div className="accordion accordion-flush" id="accordionFlushOnOn">
                <div className="accordion-item">
                  <div className="accordion-header">
                    <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      Tracklist
                    </button>
                  </div>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOnOn">
                    <div className="accordion-body tracklist">
                      <ol>
                        <p>
                          <li>RIFT (Erased Your Face) (Pavel Remix)</li>
                          <li>Membrane (Etienne Machine Remix)</li>
                          <li>Selfspace (Melatonin Remix)</li>
                          <li>Alarms (Aime Eyes Remix)</li>
                        </p>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>


              <div className="accordion-item">
                <div className="accordion-header">
                  <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    Credits
                  </button>
                </div>
                <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOnOn">
                  <div className="accordion-body">
                    <p>
                      Membrane (Etienne Machine Remix) Mixed by Alexis Sudan at A.K.A Studio, Lausanne CH<br />
                      Mastering : Alexis Sudan at A.K.A Studio, Lausanne CH<br />
                      Graphism : ETC Design & Stéphan Nappez<br />
                      Many thanks to the people mentioned above and to Yvan Vindret (Melatonin), Sacha Dumais (Aime Eyes), Etienne Bel (Pavel), La Machinerie, Nestor and the Blizzard Audio Club team
                      <br /><br />
                    </p>
                  </div>
                </div>
              </div>

              <h2 style={{ marginTop: '1.5rem' }}>Off & Off</h2>
              <img className='contentImg' src={offAndOffCover} alt="off anf off cover" />
              <ul className='streamingList' style={{ justifyContent: 'start' }}>
                <li className='streamingItem'>
                  <a href="https://etiennemachine.bandcamp.com/album/off-off" target='_blank' rel='noreferrer'>
                    <img src={bandcamp} alt="bandcamp" />
                  </a>
                </li>
                <li className='streamingItem'>
                  <a href="https://open.spotify.com/album/17910VviaHMCA8Zs4qTlfG?si=dzC--3Z1Q_WTVONt54cTIg" target='_blank' rel='noreferrer'>
                    <img src={spotify} alt="spotify" />
                  </a>
                </li>
              </ul>
              <p>released March 26, 2021</p>

              <div className="accordion accordion-flush" id="accordionFlushOffOff">
                <div className="accordion-item">
                  <div className="accordion-header">
                    <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                      Tracklist
                    </button>
                  </div>
                  <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOffOff">
                    <div className="accordion-body tracklist">
                      <ol>
                        <p>
                          <li>Alarms</li>
                          <li>Selfspace</li>
                          <li>Crocodiles</li>
                          <li>RIFT (Erased Your Face)</li>
                          <li>Membrane</li>
                          <li>Pre-Post</li>
                        </p>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>


              <div className="accordion-item">
                <div className="accordion-header">
                  <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                    Credits
                  </button>
                </div>
                <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushOffOff">
                  <div className="accordion-body">
                    <p>All songs written, composed and produced by Etienne Machine</p>
                    <p>Etienne Machine are: Arno Cuendet, Julien Palluel Kochnitzky, Justine Tornay, Maic Anthoine, Mathieu Nuzzo, Thibault Besuchet</p>
                    <p>Vocals on « Selfspace », « Crocodiles » and « Membrane » by Emile Schaffner</p>
                    <p>
                      Mixed by Alexis Sudan (ass.: Marco Ballerini, Stéphane Rezzadore) @ AKA Studio, Lausanne, CH<br />
                      Mastered by John Davis @ Metropolis Studios, London, UK<br />
                      Graphic Design by ETC Design, Stéphan Nappez and Simon Jodry<br />
                      Special Thanks to Sacha Ruffieux, Etienne Bel, La Machinerie, … and the many people surrounding us<br /><br />
                    </p>
                  </div>
                </div>
              </div>

              <h2 style={{ marginTop: '1.5rem' }}>Etienne Machine</h2>
              <img className='contentImg' src={etienneMachineCover} alt="etienne machine cover" />
              <ul className='streamingList' style={{ justifyContent: 'start' }}>
                <li className='streamingItem'>
                  <a href="https://etiennemachine.bandcamp.com/album/etienne-machine" target='_blank' rel='noreferrer'>
                    <img src={bandcamp} alt="bandcamp" />
                  </a>
                </li>
                <li className='streamingItem'>
                  <a href="https://open.spotify.com/album/3cHXYYLN5UXebmtA2pnX7v?si=mKpaNjD6TgqYZYrEKt5EFw" target='_blank' rel='noreferrer'>
                    <img src={spotify} alt="spotify" />
                  </a>
                </li>
              </ul>
              <p>released February 4, 2019</p>

              <div className="accordion accordion-flush" id="accordionFlushEtienneMachine">
                <div className="accordion-item">
                  <div className="accordion-header">
                    <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                      Tracklist
                    </button>
                  </div>
                  <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushEtienneMachine">
                    <div className="accordion-body tracklist">
                      <ol>
                        <p>
                          <li>Indian Summer</li>
                          <li>Bartender</li>
                          <li>Triangle</li>
                          <li>Voyager</li>
                        </p>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>


              <div className="accordion-item">
                <div className="accordion-header">
                  <button className="collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                    Credits
                  </button>
                </div>
                <div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushEtienneMachine">
                  <div className="accordion-body">
                    <p>Mixed by Alexis Sudan @ AKA Studio, Lausanne, CH<br />
                      Mastered by Brian Lucey @ Magic Garden Mastering, Los Angels, USA<br />
                      Thanks to Priscille Matthey and Chris Barker, Vocals on Triangle and Bartender<br />
                      Graphic Design by ETC Design</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {data[lang].bandBio.map((el, i) => {
        return (
          <p key={i}>
            {el}
          </p>
        )
      })}
    </div>
  );
}

const Socials = ({ lang }) => {

  const today = new Date().setHours(0, 0, 0, 0);

  console.log(today);

  // const month = today.getMonth() + 1;
  // const year = today.getFullYear();
  // const date = today.getDate();
  // const currentDate = date + "/" + month + "/" + year;

  return (
    <div id='socials'>

      <h1>CONCERTS</h1>

      {data['concerts'].filter((el) => new Date(el['dateForComparison']).setHours(0, 0, 0, 0) >= today).map((el, i) => (
        <div key={i} style={{ marginBottom: '1rem' }} >

          <h2>{el['venue']}</h2>
          <p>
            {el['city']}<br />
            {el['date']}<br />
          </p>
          <div>
            {el['tickets'] !== null ? <a href={el['tickets']} target='_blank' rel='noreferrer'>
              <button style={{ width: 'unset', marginRight: '1rem' }}>tickets</button>
            </a> : null}
            {el['info'] !== null ? <a href={el['info']} target='_blank' rel='noreferrer'>
              <button style={{ width: 'unset' }}>info</button>
            </a> : null}
          </div>

        </div>

      ))}

      {data['concerts'].filter((el) => new Date(el['dateForComparison']) < today).reverse().map((el, i) => (
        <div key={i} className='strikeThrough' >

          <h2>{el['venue']}</h2>
          <p>
            {el['city']}<br />
            {el['date']}
          </p>

        </div>

      ))}

      {/* {data['concerts'].filter((el) => el['passed'] === false).map((el, i) => (
        <div key={i} style={{ marginBottom: '1rem' }} >

          <h2>{el['venue']}</h2>
          <p>
            {el['city']}<br />
            {el['date']}<br />
          </p>
          <div>
            {el['tickets'] !== null ? <a href={el['tickets']} target='_blank' rel='noreferrer'>
              <button style={{ width: 'unset', marginRight: '1rem' }}>tickets</button>
            </a> : null}
            {el['info'] !== null ? <a href={el['info']} target='_blank' rel='noreferrer'>
              <button style={{ width: 'unset' }}>info</button>
            </a> : null}
          </div>

        </div>

      ))}

      {data['concerts'].filter((el) => el['passed'] === true).map((el, i) => (
        <div key={i} className='strikeThrough' >

          <h2>{el['venue']}</h2>
          <p>
            {el['city']}<br />
            {el['date']}
          </p>

        </div>

      ))} */}



    </div >
  )
}

const Gallery = () => {

  const [content, setContent] = useState('videos');

  return (
    <div>
      <button onClick={() => setContent('videos')} style={{ width: '47.5%', marginTop: '1.5rem', marginRight: '5%', background: content === 'videos' ? '#24110c' : '#f0edc2', color: content === 'videos' ? '#f0edc2' : '#24110c' }}>videos</button>
      <button onClick={() => setContent('photos')} style={{ width: '47.5%', marginTop: '1.5rem', background: content === 'photos' ? '#24110c' : '#f0edc2', color: content === 'photos' ? '#f0edc2' : '#24110c' }}>photos</button>
      {content === 'videos' ? <Videos /> : <Photos />}
    </div>
  )
}

const Videos = () => {
  return (
    <div>

      {data['videos'].map((el, i) => (
        <iframe key={i} width="100%" height="400" src={el['link']} title={el['title']} frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
        </iframe>

      ))}

      {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/ikv-L81w4HE?si=cWM4MjnSKJQtTbc6" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/2O_CzNFHp7s?si=Sgpvey2SM193u6hr" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/BfkSjIA-zo0" title="Etienne Machine - Alarms [BAC007]" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/GTKDMtS31Kc" title="Etienne Machine - RIFT (Erased Your Face) [BAC007]" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/icBMyY2cc98" title="Off &amp; Off: Live Session - Etienne Machine" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
    </div>
  )
}

const Photos = () => {
  return (
    <div>
      <img className='galleryImg' src={gallery6} alt="gallery 6" />
      <img className='galleryImg' src={gallery7} alt="gallery 7" />
      <img className='galleryImg' src={gallery8} alt="gallery 8" />
      <img className='galleryImg' src={gallery1} alt="gallery 1" />
      <img className='galleryImg' src={gallery2} alt="gallery 2" />
      <img className='galleryImg' src={gallery3} alt="gallery 3" />
      <img className='galleryImg' src={gallery9} alt="gallery 4" />
      <img className='galleryImg' src={gallery5} alt="gallery 5" />
    </div>
  )
}

export default App;
